//Api Constants
export const API_URL = process.env.REACT_APP_RAIDEN_URL

//App URLs
export const APP_URL_LOCAL = 'http://localhost:3000';
export const APP_URL_DEV = 'https://sfpmarket.dev';
export const APP_URL_PRODUCTION = 'https://sfp.market';

//use admin roles (isAdmin)
export const ADMIN_ROLES = ['Operations', 'Developer']

//Partner Context Type
export const PARTNER = 'Partner'
export const PARTNER_GROUP = 'Partner Group'

//Admin Constant
export const NEW_RECORD = 'new_record'
export const EDIT_RECORD = 'edit_record'
export const TABLE_MODE = 'table_mode'
export const RECORD_VIEW = 'record_view'

//Roles And Permissions Module
export const NEW_ROLE = 'new_role'
export const EDIT_ROLE = 'edit_role'
export const NEW_PERMISSION = 'new_permission'
export const EDIT_PERMISSION = 'edit_permission'

//PartnerGroups
export const GROUP_ASSIGN = 'group_assign'

//Partners
export const NEW_PARTNER = 'new_partner'
export const EDIT_PARTNER = 'edit_partner'

//Distributor
export const NEW_DIST = 'new_dist'
export const EDIT_DIST = 'edit_dist'

//User
export const NEW_USER = 'new_user'
export const EDIT_USER = 'edit_user'

// HelloSign client
export const HELLOSIGN_CLIENT_ID = process.env.REACT_APP_HELLOSIGN_CLIENT_ID

// Google analytics tracking id
//export const GOOGLE_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID
export const GOOGLE_TRACKING_ID = 'UA-207789861-1'
export const GOOGLE_TRACKING_ID_GA4 = 'G-RBVDX5XDRG'

//HUBSPOT Tracking, Forms and API ID
export const HUBSPOT_CODE =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? '23514951' // sandbox code
		: '22533160' // production code

export const HUBSPOT_LOGIN_FORM_ID =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? '7e42d585-cfab-4a39-bd24-624eba59d770'
		: '56c56857-71a0-4425-a7f9-5162f8c2df79'

export const HUBSPOT_REGISTER_SUPPLIER_FORM_ID =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? '7a53a44f-43cd-4657-9d39-6dca88118a2c'
		: 'fe7b07cb-2343-458c-947c-1747caa516c0'

export const HUBSPOT_REGISTER_BUYER_FORM_ID =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? 'fcf76b06-c7c4-4463-9a22-c54f039e49cd'
		: 'bffed049-cb93-40f3-b47a-8b3c287fb82c'

export const HUBSPOT_ACTIVATE_SUPPLIER_FORM_ID =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? '1a3c3948-c9fe-438f-907c-dfccd2627e89'
		: '33006e74-a746-41e5-bf50-7e8529eced4a'

export const HUBSPOT_API_ID =
	process.env.REACT_APP_ENVIRONMENT !== 'Production'
		? 'pat-na1-b0a0f7a2-fafa-411a-96aa-b238bb95a505'
		: 'pat-na1-fa0fa480-9a53-4a61-8208-ffa738f36298'

// stripe apikey
//export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const STRIPE_KEY =
	process.env.REACT_APP_ENVIRONMENT === 'Production'
		? 'pk_live_51IWirmGbcVdtvbqL1WnRRJpxhSbgAVOOUfAi9DqnxYyRvv42PqMZB3gfHtZdfxKu7PBzRMDPbVKM7JNatnLfXRga00Kkhkv8RE'
		: 'pk_test_51IWirmGbcVdtvbqLsAVzslqK42l1RbXYVIZZoUzUBUuoNwGV9ximtyLfAg4eeqpS1di0xUHxJbk65OQYEpEemyv000Vv3d2i0U'

// SFA SSO URL
// export const SFA_SSO_URL = process.env.REACT_APP_SFA_SSO_URL
export const SFA_SSO_URL =
	'https://specialtyfood.com/util/inf_aisle_sso/?returnUrl=https://engine.sfp.market/api/v1/auth/sso/sfa/login'
/*export const SFA_SSO_URL =
	'https://ccj-staging.specialtyfood.com/util/inf_aisle_sso/?returnUrl=https://pp3-be-demo.sfpmarket.dev/api/v1/auth/sso/sfa/login' */

export const time_zone_options = [
	{ value: 'America/New_York', label: 'US Eastern' },
	{ value: 'America/Los_Angeles', label: 'US Pacific' },
	{ value: 'America/Chicago', label: 'US Central' },
	{ value: 'America/Denver', label: 'US Mountain' },
	{ value: 'America/Phoenix', label: 'US Mountain (No DST)' }
]

const urlRegex =
	/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/

//Form Fields Validation
export const validate = {
	required: value => (value || value === 0 ? undefined : 'Required'),
	number: value =>
		value && isNaN(Number(value)) ? 'Must be a number' : undefined,
	integer: value =>
		value && !/^[0-9]+$/.test(value) ? 'Must be an integer' : undefined,
	gt0: value => (value > 0 ? undefined : 'Must be greater than 0'),
	email: value =>
		value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)
			? 'Invalid email address'
			: undefined,
	url: value => (value && !urlRegex.test(value) ? 'Invalid URL' : undefined),
	zipcode: value =>
		value && /[0-9]{5}(\s?-\s?[0-9]{4})?$/.test(value)
			? undefined
			: 'Please enter a valid zipcode',
	validUPC: value => value && (isValidUPC(value) ? undefined : 'Invalid UPC'),
	moneyString: value =>
		value && /^\d*(\.\d{0,2})?$/.test(value)
			? undefined
			: 'Must be a valid currency amount',
	sampleStatus: (value, allValues) =>
		allValues.status === 0 || (allValues.status !== 0 && value)
			? undefined
			: 'Required',
	urlSafe: value => (/^[a-zA-Z0-9-_]*$/.test(value) ? undefined : 'Only alphanumeric characters, underscores, and hyphens are allowed'),
}

export const countryList = ['US', 'CA']

export const provinceList = [
	'Clear Selection',
	'AB',
	'BC',
	'MB',
	'NB',
	'NS',
	'NL',
	'ON',
	'PE',
	'SK',
	'QC'
]

//US States List
export const stateList = [
	'Clear Selection',
	'AL',
	'AK',
	'AS',
	'AZ',
	'AR',
	'CA',
	'CO',
	'CT',
	'DE',
	'DC',
	'FM',
	'FL',
	'GA',
	'GU',
	'HI',
	'ID',
	'IL',
	'IN',
	'IA',
	'KS',
	'KY',
	'LA',
	'ME',
	'MH',
	'MD',
	'MA',
	'MI',
	'MN',
	'MS',
	'MO',
	'MT',
	'NE',
	'NV',
	'NH',
	'NJ',
	'NM',
	'NY',
	'NC',
	'ND',
	'MP',
	'OH',
	'OK',
	'OR',
	'PW',
	'PA',
	'PR',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VT',
	'VI',
	'VA',
	'WA',
	'WV',
	'WI',
	'WY'
]

export const listOfCountries = [
	'United States',
	'Afghanistan',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bhutan',
	'Bolivia',
	'Bosnia and Herzegovina',
	'Botswana',
	'Brazil',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cabo Verde',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Comoros',
	'Congo',
	'Costa Rica',
	"Côte d'Ivoire",
	'Croatia',
	'Cuba',
	'Cyprus',
	'Czech Republic (Czechia)',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'DR Congo',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Eswatini',
	'Ethiopia',
	'Fiji',
	'Finland',
	'France',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Greece',
	'Grenada',
	'Guatemala',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Holy See',
	'Honduras',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Ireland',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'Kuwait',
	'Kyrgyzstan',
	'Laos',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Mauritania',
	'Mauritius',
	'Mexico',
	'Micronesia',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'North Korea',
	'North Macedonia',
	'Norway',
	'Oman',
	'Pakistan',
	'Palau',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Poland',
	'Portugal',
	'Qatar',
	'Romania',
	'Russia',
	'Rwanda',
	'Saint Kitts & Nevis',
	'Saint Lucia',
	'Samoa',
	'San Marino',
	'Sao Tome & Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Korea',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'St. Vincent & Grenadines',
	'State of Palestine',
	'Sudan',
	'Suriname',
	'Sweden',
	'Switzerland',
	'Syria',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Venezuela',
	'Vietnam',
	'Yemen',
	'Zambia',
	'Zimbabwe'
]

export const devUrls = [
	{
		name: 'Developer Menu'
	}
]

export const systemLinks = [
	{
		permission: 'store-faqs',
		icon: 'fa fa-question-circle',
		name: 'Store FAQs',
		link: '/admin/store-faqs',
		dev: false
	},
	{
		permission: 'categories',
		icon: 'fa fa-sitemap',
		name: 'Categories',
		link: '/admin/categories',
		dev: false
	},
	{
		permission: 'custom-attributes',
		icon: 'fa fa-question-circle',
		name: 'Custom Attributes',
		link: '/admin/custom-attributes',
		dev: false
	},
	{
		permission: 'distributors',
		icon: 'fa fa-building',
		name: 'Distributors',
		link: '/admin/distributors',
		dev: false
	},
	{
		permission: 'form-builder',
		icon: 'fab fa-wpforms',
		name: 'Form Builder',
		link: '/admin/formbuilder',
		dev: true
	},
	{
		permission: 'partners',
		icon: 'fa fa-users',
		name: 'Partners',
		link: '/admin/partners',
		dev: false
	},
	{
		permission: 'partner-groups',
		icon: 'fa fa-cubes',
		name: 'Partner Groups',
		link: '/admin/partnergroups',
		dev: false
	},
	{
		permission: 'potential-orders',
		icon: 'fas fa-receipt',
		name: 'Potential Orders',
		link: '/admin/potential-orders',
		dev: false
	},
	{
		permission: 'promotions',
		icon: 'fas fa-tags',
		name: 'Promotions',
		link: '/admin/promotions',
		dev: false
	},
	{
		permission: 'recent-users',
		icon: 'fa fa-users',
		name: 'Recent Users',
		link: 'modal',
		dev: false
	},
	{
		permission: 'roles',
		icon: 'fa fa-check',
		name: 'Roles',
		link: '/admin/roles',
		dev: false
	},
	{
		permission: 'stores',
		icon: 'fa fa-store',
		name: 'Stores',
		link: '/admin/stores',
		dev: false
	},
	{
		permission: 'table-definition',
		icon: 'fa fa-table',
		name: 'Table Defintion',
		link: '/admin/tables-defs',
		dev: false
	},
	// {
	// 	icon: 'fas fa-project-diagram',
	// 	name: 'Departments',
	// 	link: '/admin/Departments',
	//	dev:false
	// },
	{
		permission: 'units',
		icon: 'fa fa-balance-scale',
		name: 'Units',
		link: '/admin/units',
		dev: false
	},
	{
		permission: 'login-logos',
		icon: 'fa fa-sim-card',
		name: 'Login Logos',
		link: '/admin/logos',
		dev: false
	},
	{
		permission: 'users',
		icon: 'fa fa-user',
		name: 'Users',
		link: '/admin/users',
		dev: false
	},
	{
		permission: 'client-widgets',
		icon: 'fa fa-laptop-code',
		name: 'Client Widgets',
		link: '/admin/client-widgets',
		dev: false
	},
	{
		permission: 'brokers',
		icon: 'fa fa-laptop-code',
		name: 'Brokers',
		link: '/admin/brokers/',
		dev: false
	},
	{
		permission: 'vendor-groups',
		icon: 'fa fa-laptop-code',
		name: 'Vendor Groups',
		link: '/admin/vendor-groups',
		dev: false
	},
	{
		permission: 'sample-requests',
		icon: 'fa fa-laptop-code',
		name: 'Sample Requests',
		link: '/admin/sample-requests',
		dev: false
	},
	{
		permission: 'product-filters-sets',
		icon: 'fa fa-laptop-code',
		name: 'Product Filters Sets',
		link: '/admin/product-filter-sets',
		dev: false
	},
	{
		permission: 'past-price-updates',
		icon: 'fa fa-calendar-alt',
		name: 'Past Price Updates',
		link: '/admin/price-scheduler/past',
		dev: false
    },
    {
        permission: 'product-recalls',
		icon: 'fa fa-laptop-code',
		name: 'Product Recall',
		link: '/admin/product-recall',
		dev: false
	}
]

export const supportLinks = [
	{
		name: 'FAQs',
		link: '/support/faq',
		dev: false
	},
	{
		name: 'Video Tutorials',
		link: '/support/tutorial',
		dev: false
	}
]

export const supplierUnits = [
	{ value: 0, label: 'CT' },
	{ value: 1, label: 'EA' },
	{ value: 2, label: 'LB' }
]

export const supplierProductStatus = [
	{ value: 0, label: 'Closed' },
	{ value: 1, label: 'Open' }
]

export const productStatus = [
	{ value: 0, label: 'Closed' },
	{ value: 1, label: 'Open' },
	{ value: 2, label: 'FN Rejected - Taste Test' },
	{ value: 3, label: 'FN Rejected - Ingredients' },
	{ value: 6, label: 'FN Rejected - Quality' },
	{ value: 4, label: 'In Process' },
	{ value: 5, label: 'Discontinued' }
]

export const productStatusLabels = [
	'Unavailable',
	'Available',
	'SFP Rejected - Taste Test',
	'SFP Rejected - Ingredients',
	'SFP Rejected - Quality',
	'Missing Information',
	'Discontinued'
]

export const productSpecialDietOptions = [
	{ value: 'Dairy Free', label: 'Dairy Free' },
	{ value: 'Gluten Free', label: 'Gluten Free' },
	{ value: 'High Protein', label: 'High Protein' },
	{ value: 'Keto', label: 'Keto' },
	{ value: 'Low Carb', label: 'Low Carb' },
	{ value: 'Low Salt', label: 'Low Salt' },
	{ value: 'Low Sugar', label: 'Low Sugar' },
	{ value: 'Macrobiotic', label: 'Macrobiotic' },
	{ value: 'No Added Sugar', label: 'No Added Sugar' },
	{ value: 'Nut Free', label: 'Nut Free' },
	{ value: 'Paleo', label: 'Paleo' },
	{ value: 'Soy Free', label: 'Soy Free' },
	{ value: 'Sugar Free', label: 'Sugar Free' },
	{ value: 'Vegan', label: 'Vegan' },
	{ value: 'Vegetarian', label: 'Vegetarian' }
]

export const productSpecialtyAttributesOptions = [
	{ value: 'Antibiotic Free', label: 'Antibiotic Free' },
	{ value: 'All-Natural', label: 'All-Natural' },
	{ value: 'Cage Free', label: 'Cage Free' },
	{ value: 'Dairy Free', label: 'Dairy Free' },
	{ value: 'Eco-Friendly', label: 'Eco-Friendly' },
	{ value: 'Ethical', label: 'Ethical' },
	{ value: 'Fair Trade', label: 'Fair Trade' },
	{ value: 'Kosher', label: 'Kosher' },
	{ value: 'Locally Sourced', label: 'Locally Sourced' },
	{ value: 'No Added Hormones', label: 'No Added Hormones' },
	{ value: 'Non GMO', label: 'Non GMO' },
	{ value: 'Organic', label: 'Organic' },
	{ value: 'Plant Based', label: 'Plant Based' },
	{ value: 'Sustainable', label: 'Sustainable' }
]

export const productSeasonThemeList = [
	{ value: '4th of July', label: '4th of July' },
	{ value: 'Allergy', label: 'Allergy' },
	{ value: 'BBQ', label: 'BBQ' },
	{ value: 'Back to School', label: 'Back to School' },
	{ value: 'Black History Month', label: 'Black History Month' },
	{ value: 'Celiac Awareness', label: 'Celiac Awareness' },
	{ value: 'Chanukah', label: 'Chanukah' },
	{ value: 'Chinese New Year', label: 'Chinese New Year' },
	{ value: 'Christmas', label: 'Christmas' },
	{ value: 'Cinco de Mayo', label: 'Cinco de Mayo' },
	{ value: 'Cold & Flu', label: 'Cold & Flu' },
	{ value: 'Dairy Month', label: 'Dairy Month' },
	{ value: 'Earth Day', label: 'Earth Day' },
	{ value: 'Easter', label: 'Easter' },
	{ value: "Father's Day", label: "Father's Day" },
	{ value: 'Grilling', label: 'Grilling' },
	{ value: 'Halloween', label: 'Halloween' },
	{ value: 'Heart Healthy', label: 'Heart Healthy' },
	{ value: 'Hispanic Heritage', label: 'Hispanic Heritage' },
	{ value: 'Holiday Season', label: 'Holiday Season' },
	{ value: 'Kentucky Derby Day', label: 'Kentucky Derby Day' },
	{ value: 'Kwanzaa', label: 'Kwanzaa' },
	{ value: 'Labor Day', label: 'Labor Day' },
	{ value: 'Lent', label: 'Lent' },
	{ value: 'March Madness', label: 'March Madness' },
	{ value: 'Mardi Gras', label: 'Mardi Gras' },
	{ value: 'Memorial Day', label: 'Memorial Day' },
	{ value: "Mother's Day", label: "Mother's Day" },
	{ value: 'New Years', label: 'New Years' },
	{ value: 'Oktoberfest', label: 'Oktoberfest' },
	{ value: 'Oscars Night', label: 'Oscars Night' },
	{ value: 'Passover', label: 'Passover' },
	{ value: 'Picnic Time', label: 'Picnic Time' },
	{ value: 'Purim', label: 'Purim' },
	{ value: 'Ramadan', label: 'Ramadan' },
	{ value: 'Rosh Hashanah', label: 'Rosh Hashanah' },
	{ value: 'Shauvet', label: 'Shauvet' },
	{ value: 'St Patricks Day', label: 'St Patricks Day' },
	{ value: 'Super Bowl', label: 'Super Bowl' },
	{ value: 'Thanksgiving', label: 'Thanksgiving' },
	{ value: 'Valentines Day', label: 'Valentines Day' },
	{ value: 'Yom Kippur', label: 'Yom Kippur' }
]

export const favoriteOptions = [
	{ value: 'Show only favorites', label: 'Show only favorites' }
]

export const productMerchandisingLocations = [
	{ value: 'Bakery', label: 'Bakery' },
	{ value: 'Beer', label: 'Beer' },
	{ value: 'Cheese', label: 'Cheese' },
	{ value: 'Dairy', label: 'Dairy' },
	{ value: 'Deli', label: 'Deli' },
	{ value: 'Frozen', label: 'Frozen' },
	{ value: 'Grocery', label: 'Grocery' },
	{ value: 'Meat', label: 'Meat' },
	{ value: 'Nonfood/Pharmacy', label: 'Nonfood/Pharmacy' },
	{ value: 'Prepared Foods', label: 'Prepared Foods' },
	{ value: 'Produce', label: 'Produce' },
	{ value: 'Seafood', label: 'Seafood' },
	{ value: 'Wine & Spirits', label: 'Wine & Spirits' }
]

export const productNewToMarketOptions = [
	{ value: 0, label: 'Not new to market' },
	{ value: 1, label: 'Last 30 days' },
	{ value: 2, label: 'within 3 months' },
	{ value: 3, label: 'within 6 months' },
	{ value: 4, label: 'within 9 months' },
	{ value: 5, label: 'within past 12 months' }
]

export const onboardingStatus = [
	{ value: 0, label: 'In Process' },
	{ value: 1, label: 'Ready for Submit' },
	{ value: 2, label: 'Submitted' },
	{ value: 3, label: 'Reviewed' },
	{ value: 4, label: 'Approved' }
]

export const monthOptions = [
	{ value: 'January', label: 'January' },
	{ value: 'February', label: 'February' },
	{ value: 'March', label: 'March' },
	{ value: 'April', label: 'April' },
	{ value: 'May', label: 'May' },
	{ value: 'June', label: 'June' },
	{ value: 'July', label: 'July' },
	{ value: 'August', label: 'August' },
	{ value: 'September', label: 'September' },
	{ value: 'October', label: 'October' },
	{ value: 'November', label: 'November' },
	{ value: 'December', label: 'December' }
]

export const purchaseStatus = [
	{ value: 0, text: 'Not yet Purchased' },
	{ value: 1, text: 'Purchased' },
	{ value: 2, text: 'Swapped...' },
	{ value: 3, text: 'Omitted' },
	{ value: 4, text: 'Reset' }
]

export const brokers = [
	{ label: 'CrossCountry Marketing', value: 1 },
	{ label: 'VCM Foods', value: 2 },
	{ label: 'Wasserman Food Sales & Marketing', value: 3 },
	{ label: 'Double D Specialty Foods & Services', value: 4 },
	{ label: 'FDM', value: 5 },
	{ label: 'D&H Marketing Group', value: 6 },
	{ label: 'CPG Conduit', value: 7 }
]

export const vendorGroups = [
	{ label: 'Specialty Food Association', value: 1 },
	{ label: 'Emerge', value: 2 },
	{ label: 'Made In Oklahoma', value: 3 },
	{ label: 'NC SFA', value: 4 },
	{ label: 'SC SFA', value: 5 },
	{ label: 'VA SFA', value: 6 },
	{ label: 'World of Latino Cuisine', value: 7 },
	{ label: 'Duo Marketplace', value: 8 },
	{ label: 'Latino Restaurant Assocation', value: 9 },
	{ label: 'Mr. Checkout', value: 10 },
	{ label: 'Beacon', value: 12}
]

export const productTypes = [
	{ label: 'Shelf Stable', value: 0 },
	{ label: 'Shelf Stable + Refrigerated or Frozen', value: 1 },
	{ label: 'Refrigerated', value: 2 },
	{ label: 'Frozen', value: 3 },
	{ label: 'Frozen/Refrigerated', value: 4 }
]

export const vendorTypes = [
	{ label: 'Yes, I can deliver or arrange delivery of my products', value: 0 },
	{ label: 'Yes, I use UPS/USPS/FedEx to ship my items', value: 1 },
	{ label: 'No (other)', value: 2 }
]

export const rejectionReasons = [
	{ value: 1, text: 'Out of stock' },
	{ value: 2, text: 'Quality Issues' },
	{ value: 3, text: 'Not Meet Spec' },
	{ value: 4, text: 'Temp Issue' },
	{ value: 5, text: 'Misdelivered' },
	{ value: 6, text: 'Store Returned/Rejected' },
	{ value: 7, text: 'Other' },
	{ value: 8, text: 'Discontinued' }
]

export const partnerDataType = [
	{ value: 0, text: 'Connection' },
	{ value: 1, text: 'General Settings' },
	{ value: 2, text: 'Order Settings' },
	{ value: 3, text: 'Billing Settings' },
	{ value: 4, text: 'Produce Settings' },
	{ value: 5, text: 'Non-Produce Settings' }
]

export const isValidUPC = gtin => {
	if (isNaN(gtin)) return false
	if (!CheckBasics(gtin)) return false

	// Define fixed variables
	var CheckDigitArray = []
	var gtinMaths = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3]
	var modifier = 17 - (gtin.length - 1) // Gets the position to place first digit in array
	var BarcodeArray = gtin.split('') // Split barcode at each digit into array
	var gtinLength = gtin.length
	var tmpCheckDigit = 0
	var tmpCheckSum = 0

	// Run through and put digits into multiplication table
	for (var i = 0; i < gtinLength - 1; i++) {
		CheckDigitArray[modifier + i] = BarcodeArray[i] // Add barcode digits to Multiplication Table
	}

	// Calculate "Sum" of barcode digits
	for (var m = modifier; m < 17; m++) {
		tmpCheckSum += CheckDigitArray[m] * gtinMaths[m]
	}

	// Difference from Rounded-Up-To-Nearest-10 - Fianl Check Digit Calculation
	tmpCheckDigit = Math.ceil(tmpCheckSum / 10) * 10 - parseInt(tmpCheckSum)

	// Check if last digit is same as calculated check digit
	if (parseInt(gtin.slice(-1), 10) === tmpCheckDigit) return true
	return false
}

const CheckBasics = gtin => {
	// Check length is ok
	if (gtin.length != 14) return false

	// Check whether is a number
	var m = gtin.match(/\d+/)
	if (isNaN(m[0])) return false

	// Is valid, return true
	return true
}

export const SPECIALTY_FIELDS = [
	'antibiotic_free',
	'cage_free',
	'gluten_free',
	'kosher',
	'low_salt',
	'no_added_hormones',
	'no_added_sugar',
	'non_gmo',
	'organic',
	'paleo',
	'sugar_free',
	'soy_free',
	'vegan',
	'women_owned',
	'keto',
	'dairy_free',
	'nut_free'
]

export const DISTINCTIONS_FIELDS = [
	'disabled_persons',
	'lgbtq_identified_persons',
	'service_disabled_veterans',
	'women_owned',
	'employee_owned',
	'b_corp',
	'minority_owned',
	'kosher',
	'organic',
	'gluten_free',
	'non_gmo',
	'paleo',
	'vegan',
	'vegetarian',
	'veteran_owned'
]

export const VENDORS_DISTINCTIONS = [
	'Women Owned',
	'Employee Owned',
	'B Corp',
	'Minority Owned',
	'Certified Kosher',
	'Certified Organic',
	'Gluten_free',
	'Non Gmo',
	'Paleo',
	'Vegan',
	'Vegetarian',
	'Veteran Owned'
]

export const VENDORS_DB_MODE = {
	ALL_VENDORS: 0,
	CONTACTED: 1,
	MY_VENDORS: 2
}

export const VENDORS_DB_MSG_BODY = {
	noVendor: `is interesting in sourcing your
	products for their virtual supply chain program. They requested that
	you submit your business and product details through the Specialty
	Food Marketplace onboarding portal. Click the link below to sign up
	with`,
	withVendor: `is interesting in sourcing your
	products for their virtual supply chain program. They requested that
	you submit your business and product details through the Specialty
	Food Marketplace onboarding portal. Click the link below to finish your profile and sell your products to`
}

export const FAQ_VIDEO_TITLES = [
	'Supplier Quickstart',
	'How to Import items',
	'How to Create Promotions'
]

export const FAQ_VIDEO_CODES = [
	'sfa-quickstart',
	'howto-import-items',
	'howto-create-promotions'
]

export const PRODUCT_MULTI_SELECT_FIELDS = [
	'product_seasonality',
	'specialty_attributes',
	'special_diet_options',
	'sofi_award_winner',
	'seasonal_item',
	'merchandising_locations'
]

export const VENDOR_SERVICE_TYPES = [
	{ label: 'Foodservice only', value: 0 },
	{ label: 'Retail ready items only', value: 1 },
	{ label: 'Retail ready and foodservice', value: 2 },
	{ label: 'Other', value: 3 }
]

export const productTemperatureSelectOptions = [
	{ label: 'Shelf Stable', value: 1 },
	{ label: 'Chilled', value: 2 },
	{ label: 'Frozen', value: 3 },
	{ label: 'Frozen/Refrigerated', value: 4 }
]
