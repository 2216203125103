import React, { Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {getStyleInformation} from "../../utils/getStyleInformation";

//compose isMobile set in props - hide by default in mobile breakpoint

function BreadCrumb({
	breadcrumb,
	user,
	isHide = false,
	isMobile,
	isHideOnMobile = true
}) {
	const isHideDisplay = useMemo(() => {
		const userCrumb =
			user && user.is_supplier
				? user.supplier.data.is_onboarding !== 1
				: Boolean(user)
		return !userCrumb || isHide || (isHideOnMobile && isMobile)
	}, [isHide, isHideOnMobile, isMobile, user])
	//console.log("crumb", props, hideCrumb, isMobile)
	const title = document.getElementsByTagName('title');

	const {appTitle} = getStyleInformation()

	if (breadcrumb.length > 0) {
		breadcrumb.map((item) => (
			item.isLink
			? (title[0].innerHTML = appTitle )
			: (title[0].innerHTML = `${appTitle} - ${item.label.charAt(0).toUpperCase()}${item.label.slice(1)}`)
		))
	} else {
		title[0].innerHTML = appTitle;
	}

	return !isHideDisplay ? (
		<div className="py-2">
			<div className="container-fluid">
				<div className="row justify-content-between">
					<div className="col text-start">
						<ol className="breadcrumb icon-angle-right mb-0">
							{breadcrumb.length > 0 && (
								<Fragment>
									<li className="breadcrumb-item">
										<Link to="/" className="text-info brand-table-link">
											<i className="fas fa-home me-1" />
											<span>Home</span>
										</Link>
									</li>
									{breadcrumb.map((item, idx) => (
										<li className="breadcrumb-item" key={idx}>
											{item.isLink ? (
												<Link
													to={item.link}
													className="text-capitalize text-info">
													{item.label}
												</Link>
											) : (
												<span className="text-capitalize">{item.label}</span>
											)}
										</li>
									))}
								</Fragment>
							)}
						</ol>
					</div>
					<div className="col text-end d-flex">
						{user && (
							<span className="font-weight-light align-self-center ms-auto">
								{user.name} - {user.role_name}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	) : null
}

export default BreadCrumb
