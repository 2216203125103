import React, { Component } from 'react'
import {
	Navbar as NavigationBar,
	NavbarToggler,
	Nav,
	NavItem
} from 'reactstrap'
import Logo from '../logo'
import { connect } from 'react-redux'
import DropdownUser from './DropdownUser'
import DropDownMenu from './DropDownMenu'
import PartnerContext from './PartnerContext'
import { Link } from 'react-router-dom'

import NotifySuppliers from './NotifySuppliers'
import SupplierUpdates from './SupplierUpdates'
import SupplierOnboardingStatus from './SupplierOnboardingStatus'
import CartDropdown from './CartDropdown'

import '../../scss/elements/navbar-2.scss'
import { systemLinks } from 'helpers/Constants'
import HasRole from 'hoc/HasRole'
import { startTour } from 'actions'
import SupplierUpdatesForOrder from "./SupplierUpdatesForOrder";
import ScanItem from "./ScanItem";
import PriceSchedulerNavIcon from "./PriceSchedulerNavIcon";
import {getStyleInformation} from "../../utils/getStyleInformation";

class Navbar extends Component {
	render() {
		const { user, partners, isMobile} = this.props
		let showContextMenu =
			partners && partners.length > 1 && user.is_supplier !== 1

		let {navClass, contentClass, dropdownClass} = getStyleInformation()

		if (this.props.user) {
			return (
				<NavigationBar
					expand="lg"
					dark
					color="dark"
					className={`navbar navbar-2 ${navClass}`}
					container={`fluid justify-content-start`}>
					<NavbarToggler
						onClick={this.props.toggleDrawer}
						style={{ border: 'none', padding: 0 }}
					/>
					<div className="ms-2 ms-lg-0 me-0 navbar-brand py-0">
						<Logo />
					</div>
					<Nav style={{ color: 'white', marginLeft: 'auto' }}>
						{showContextMenu && (
							<NavItem className="desktop nav-item nav-item-dropdown me-2">
								<PartnerContext className={navClass} />
							</NavItem>
						)}
						{user.is_supplier !== 1 && (
							<NavItem className="desktop nav-item nav-item-dropdown">
								<span
									id="order-start"
									className={`ant-btn ant-btn-background-ghost ant-dropdown-trigger ${navClass}`}>
									<Link
										className="align-middle mt-5"
										to="/order/start">
										START ORDER
									</Link>
								</span>
							</NavItem>
						)}
						{user.is_supplier !== 1 && (
							<NavItem className="mobile nav-item nav-item-dropdown">
								<CartDropdown className={contentClass} dropdownClassName={dropdownClass} isMobile={isMobile}/>
							</NavItem>
						)}
						{user.is_supplier !== 1 && (
							<NavItem className="mobile nav-item nav-item-dropdown">
								<ScanItem />
							</NavItem>
						)}
						<NavItem className="mobile nav-item nav-item-dropdown">
							<DropdownUser dropdownClassName={dropdownClass}/>
						</NavItem>
						<HasRole roles={['Operations', 'Developer']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<NotifySuppliers />
							</NavItem>
						</HasRole>
						<HasRole roles={['Operations', 'Developer']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<SupplierUpdates />
							</NavItem>
						</HasRole>
						<HasRole roles={['Operations', 'Developer']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<PriceSchedulerNavIcon />
							</NavItem>
						</HasRole>
						<HasRole roles={['Operations', 'Developer']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<SupplierUpdatesForOrder />
							</NavItem>
						</HasRole>
						<HasRole roles={['Operations', 'Developer', 'Vendor Outreach']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<SupplierOnboardingStatus />
							</NavItem>
						</HasRole>
						<HasRole roles={['Operations', 'Developer']}>
							<NavItem className="desktop nav-item nav-item-dropdown">
								<DropDownMenu links={systemLinks} icon="cogs" />
							</NavItem>
						</HasRole>
					</Nav>
				</NavigationBar>
			)
		} else {
			return <div />
		}
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.userData,
		partners: state.partners.partners,
		//auth: state.auth.authenticated
	}
}

export default connect(mapStateToProps, { startTour })(Navbar)
