import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../scss/elements/top-navigation-1.scss'
import HasRole from 'hoc/HasRole'
import { supportLinks } from 'helpers/Constants'
import DropDownMenu from 'elements/navbar/DropDownMenu'
import _ from 'lodash'
import { topLinkCls } from '../helpers'
import {getStyleInformation} from "../../utils/getStyleInformation";

const Menu = ({ nav, subdomainClass, user }) => {
	let hideSampleRequest = user.hide_sample_request_nav && nav.title === 'Vendors'
	return (
	<ul className="top-menu-items list-unstyled">
		<li className="list-item  px-2 px-xl-3">
			{nav.items.length > 0 && !hideSampleRequest ? (
				<a className={`${topLinkCls} ${subdomainClass}`} id={nav.id}>
					{nav.title}
				</a>
			) : (
				<Link to={nav.url} className={topLinkCls} id={nav.id}>
					<div>{nav.title}</div>
				</Link>
			)}
			<ul className="list-unstyled d-flex flex-column">
				{!hideSampleRequest && nav.items.map((item, i) => (
					item.title === 'Sample Requests' && user.hide_sample_request_nav ? null :
					<li className={`list-item bg-dark ${subdomainClass}`} key={i}>
						<Link to={item.url} className={topLinkCls} id={nav.id}>
							<div>{item.title}</div>
						</Link>
					</li>
				))}
			</ul>
		</li>
	</ul>
	)
}

class TopNavigation extends Component {
	componentDidMount() {
		// this.fetchStores()
	}

	render() {
		const { suppliernav, navigation, user } = this.props
		let subdomain = window.location.hostname.split('.')[0]
		let { topNavClass } = getStyleInformation()

		if (user) {
			if (user.is_supplier === 1) {
				//TODO if ops decideds we might want the user to access links during the onboarding process put logic here
				const items = suppliernav
					.map(item => item.items)
					.reduce((a, b) => [...a, ...b])
				return (
					<div
						className={`${topNavClass} bg-dark top-navigation top-navigation-1 d-flex flex-row justify-content-start align-items-center flex-nowrap`}>
						{items &&
							items.map((item, i) => {
								if (
									(user.supplier.data.is_onboarding !== 1 &&
										item.title === 'Account Setup') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Profile') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Assets') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Stores') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Products') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Upcoming Orders') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Historical Orders') ||
									(user.supplier.data.is_onboarding === 1 &&
										item.title === 'Payments')
								) {
									return null
								} else {
									return (
										<Menu key={i} nav={item} subdomainClass={topNavClass} user={user} />
									)
								}
							})}
					</div>
				)
			} else {
				const items = _.cloneDeep([...navigation])
					.map(item => item.items)
					.reduce((a, b) => [...a, ...b])

				//Add Vendors Database Link to Corresponding Users
				if (user !== null) {
					/* if (
						user.show_vendors_db === 1 &&
						user.user_permissions.includes('vendorsdb-access')
					) {
						items.push({
							id: 'vdb',
							url: '/vendors-db',
							title: 'Vendors Database',
							items: []
						})
					} */
					//Check for role specific links
					items.forEach(function(element, index) {
						if ('roles' in element) {
							if (!element.roles.includes(user.role_name)) {
								items.splice(index, 1);
							}
						}
					});
				}

				// temporarily remove the video tutorials link from the pigglywiggly marketplace
				let conditionalSupportLinks = ["pigdirect", "grocers"].includes(subdomain) ? supportLinks.filter(item => item.name !== 'Video Tutorials') : supportLinks;
				return (
					<div
						className={`${topNavClass} bg-dark top-navigation-1 d-none d-lg-flex flex-row justify-content-start align-items-center`}>
						{items &&
							items.map((item, i) => {
								if (
									item.title !== 'Developer Menu' ||
									user.is_developer === 1
								) {
									return (
										<Menu key={i} nav={item} subdomainClass={topNavClass} user={user} />
									)
								}

								return null
							})}

						<HasRole roles={['Operations', 'Developer']}>
							<ul className="top-menu-items list-unstyled">
								<li className="list-item px-2 px-xl-3">
									<Link to="/admin" className={topLinkCls}>
										Admin
									</Link>
								</li>
							</ul>
						</HasRole>
						<ul className="top-menu-items list-unstyled pe-4 ms-auto">
							<li className="list-item">
								<DropDownMenu links={conditionalSupportLinks} title="Support" />
							</li>
						</ul>
					</div>
				)
			}
		} else {
			return <div />
		}
	}
}

export default TopNavigation
