import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import '../../scss/elements/logo.scss'
import { getStyleInformation} from "../../utils/getStyleInformation";

const Logo = () => {
	const defaultLogo = (
		<Fragment>
			<i className="fa fa-bookmark fa-2x" />
			<span className="title">
				SFP Portal <sup>v3.1</sup>
				<small className="title-strap">Specialty Food Partners</small>
			</span>
		</Fragment>
	)

	const logo = getStyleInformation().logo
		? getStyleInformation().logo
		: defaultLogo

	return (
		<Link
			to="/"
			className="logo d-flex justify-content-start align-items-center flex-nowrap text-white"
			style={{ paddingLeft: "0.5em" }}>
			{logo}
		</Link>
	)
}

export default Logo
