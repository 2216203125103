import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleModal } from '../actions/modal'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getStyleInformation } from "../utils/getStyleInformation";

//adding scrollable option modal-dialog-scrollable
//config does not work in this reactstrap version

const scrollCls = 'modal-dialog-scrollable'

class ModalContainer extends Component {
	onCloseModal = () => {
		this.props.toggleModal()
	}
	render() {
		const {
			showModal,
			modalBody,
			title,
			size,
			footer,
			className,
			modalClassName,
			headerColor,
			scrollable
		} = this.props.modal

		let headColor = headerColor || 'primary'
		let dialogClasses = className ? [className] : []
		const subdomainClass = getStyleInformation().modalClass
		if (subdomainClass) dialogClasses.push(subdomainClass)
		if (scrollable) dialogClasses.push(scrollCls)
		const dCls = dialogClasses.join(' ')

		const closeButton = (
			<button
				type="button"
				className="btn-close btn-close-white"
				onClick={() => {
					this.onCloseModal()
				}}
				aria-label="Close"></button>
		)
		return (
			<Modal
				isOpen={showModal}
				toggle={this.onCloseModal}
				wrapClassName={`modal-${headColor}`}
				backdrop="static"
				size={size}
				className={dCls}
				modalClassName={modalClassName || null}>
				<ModalHeader
					toggle={this.onCloseModal}
					className="text-white bg-primary"
					close={closeButton}
					>
					<span className="text-white">{title}</span>
				</ModalHeader>
				<ModalBody>{modalBody}</ModalBody>
				{footer && (
					<ModalFooter>
						<button
							className="btn btn-secondary"
							onClick={() => {
								this.onCloseModal()
							}}>
							Close
						</button>
					</ModalFooter>
				)}
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		modal: state.modal
	}
}

export default connect(mapStateToProps, { toggleModal })(ModalContainer)
