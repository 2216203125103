import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import NotificationsSystem from 'reapop'
import theme from './notifications/reapop/reapop-theme-bootstrap'
import { getUser, resetCrumb } from './actions'
import ErrorBoundary from 'hoc/ErrorBoundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ModalContainer from './components/ModalContainer'
import {getStyleInformation} from "./utils/getStyleInformation"
import { useMemoizedCallback } from './libs/hooks'
import { getLocalAccessToken } from './api/storage.service'
import { selectUserAuthenticated } from './reducers/user'
import HistoryListener from './provider/HistoryListener'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fortawesome/fontawesome-free/css/all.css'
//ORDER MATTERS HERE - specicifity
//global css
import 'antd/dist/antd.css'
//import 'bootstrap/dist/css/bootstrap.min.css'
//libraries
import './helpers/styleSheets'
//custom
import 'scss/theme.scss'
import AppRoutes from './routes/AppRoutes'

const queryClient = new QueryClient()

function App(props) {
	const { isAuthenticated, fetchingUser, userData, resetCrumb, getUser, auth } =
		props
	const [isInitializing, setIsInitializing] = useState(true)

	const { mainClass, appTitle } = useMemo(() => {
		if (fetchingUser)
		{
			return {
				mainClass: 'sfp',
				appTitle: 'SFP Portal v3.1'
			}
		}
		let { appTitle, mainClass } = getStyleInformation()
		return {
			mainClass,
			appTitle
		}
	}, [isAuthenticated, fetchingUser])

	useEffect(() => {
		if (appTitle) {
			document.title = appTitle
		}
	}, [appTitle])

	const checkUser = useMemoizedCallback(() => {
		const storedToken = getLocalAccessToken()
		if (!isAuthenticated && auth && !fetchingUser && storedToken) {
			console.log('getUser APP')
			getUser(() => setIsInitializing(false), true, true)
		} else {
			setIsInitializing(false)
		}
	})

	useEffect(() => {
		checkUser()
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<NotificationsSystem theme={theme} />

				<ErrorBoundary>
					<AppRoutes
						subdomainClass={mainClass}
						isInitializing={isInitializing}
						//userData={userData}
					/>
				</ErrorBoundary>

				<ModalContainer />
				<HistoryListener resetCrumb={resetCrumb} />
				<ReactQueryDevtools initialIsOpen={false} />
			</BrowserRouter>
		</QueryClientProvider>
	)
}

const mapDispatchToProps = {
	getUser,
	resetCrumb
}

const mapStateToProps = state => {
	return {
		auth: state.auth.authenticated,
		isAuthenticated: selectUserAuthenticated(state),
		fetchingUser: state.user.fetchingUser,
		userData: state.user.userData
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
