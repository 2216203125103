import { lazy, Fragment } from 'react'
import EmptyViewWrapper from '../layouts/landing/EmptyViewWrapper'
import StartViewWrapper from '../layouts/landing/StartViewWrapper'
import HomeViewWrapper from '../layouts/landing/HomeViewWrapper'

/* const Login = lazy(() => import('../auth/login/Login'))
const ResetPassword = lazy(() =>
	import('../pages/reset-password/ResetPassword')
)
const BuyerRegistrationView = lazy(() =>
	import('../buyer-registration/registration/BuyerRegistrationView')
) */

const landingRoutes = [
	{
		key: 'login',
		path: '/login',
		component: lazy(() => import('../auth/login')),
		exact: true,
		layout: EmptyViewWrapper
	},
	{
		key: 'buyer-registration',
		path: '/buyer-registration',
		component: lazy(() => import('../buyer-registration/registration')),
		exact: true,
		layout: Fragment
	},
	{
		key: 'reset-password',
		path: '/reset-password/:token?',
		component: lazy(() => import('../pages/reset-password')),
		exact: true,
		layout: EmptyViewWrapper
	},
	{
		key: 'sfa-login',
		path: '/sso/sfa/login/:msg?',
		component: lazy(() => import('../pages/sso-sfa')),
		exact: true,
		layout: EmptyViewWrapper
	},
	{
		key: 'terms-of-use',
		path: '/terms-of-use',
		component: lazy(() => import('../pages/terms-of-use')),
		exact: true,
		layout: HomeViewWrapper
	},
	{
		key: 'registration-wizard',
		path: '/sso/sfa/login/wizard/:msg?',
		component: lazy(() => import('../infinite-aisle/registration-wizard')),
		exact: true,
		layout: Fragment
	},
	{
		key: 'infinite-aisle-registration',
		path: '/infinite-aisle/registration',
		component: lazy(() => import('../infinite-aisle/store-registration')),
		exact: true,
		layout: Fragment
	},
	{
		key: 'infinite-aisle-store-registration',
		path: '/infinite-aisle/quick-store-registration',
		component: lazy(() => import('../infinite-aisle/quick-store-registration')),
		exact: true,
		layout: Fragment
	},
	{
		key: 'user-registration',
		path: '/registration',
		component: lazy(() => import('../register')),
		exact: true,
		layout: HomeViewWrapper
	},
	{
		key: 'distributor-registration',
		path: '/distributor/register',
		component: lazy(() => import('../distributor/register')),
		exact: true,
		layout: HomeViewWrapper
	},
	{
		key: 'view-image',
		path: '/asset/view-image/:hashid',
		component: lazy(() => import('../pages/view')),
		exact: true,
		layout: EmptyViewWrapper
	},
	// supplier
	{
		key: 'supplier-registration',
		path: '/supplier/register/:vendorGroup?',
		component: lazy(() => import('../supplier/register')),
		exact: true,
		layout: HomeViewWrapper
	},
	{
		key: 'supplier-activate',
		path: '/supplier/activate/:type/:supplierhash/:vendorGroup?',
		component: lazy(() => import('../supplier/activate')),
		exact: true,
		layout: HomeViewWrapper
	},
	{
		key: 'supplier-sfa-registration-redirect',
		path: '/tokenredirect/:access_token/:refresh_token',
		component: lazy(() => import('../supplier/sfaredirect')),
		exact: true,
		layout: HomeViewWrapper
	},
	// specialty product/vendor profile
	{
		key: 'product-profile-start',
		path:"/start/product/view/:hashid?",
		component: lazy(() => import('../product/profile')),
		exact: true,
		isRedirectOnLogin: true,
		redirectTo: {
			path: '/product/view/',
			paramsMatch: 'hashid'
		},
		layout: StartViewWrapper
	},
	{
		key: 'vendor-profile-start',
		path:"/start/vendor/view/:vendorHash?",
		component: lazy(() => import('../vendor')),
		exact: true,
		isRedirectOnLogin: true,
		redirectTo: {
			path: '/vendor/view/',
			paramsMatch: 'vendorHash'
		},
		layout: StartViewWrapper
	},
	{
		key: 'faq-support-start',
		path:"/start/support/faq",
		component: lazy(() => import('../support/Faq')),
		exact: true,
		isRedirectOnLogin: true,
		redirectTo: {
			path: '/support/faq/',
		},
		layout: StartViewWrapper
	},
]

export default landingRoutes
