import {store} from "../index";
import {subdomainDetails} from "./subdomain";

export const getVendorGroup = () => {
    const state = store.getState();
    const userData = state.user.userData;

    if (!userData) {
        return null;
    }

    if (
        userData &&
        userData.supplier &&
        userData.supplier.data &&
        userData.supplier.data.vendor &&
        userData.supplier.data.vendor.data &&
        userData.supplier.data.vendor.data.group &&
        userData.supplier.data.vendor.data.group.data
    ) {
        return userData.supplier.data.vendor.data.group.data.short_name;
    }

    return null
};

export const getStyleInformation = (vendorGroup) => {
    let vendorGroupName = vendorGroup ? vendorGroup : getVendorGroup()
    let subdomain = window.location.hostname.split('.')[0]
    let styleReference = subdomainDetails[vendorGroupName]
        ? subdomainDetails[vendorGroupName]
        : subdomainDetails[subdomain]

    const defaultStyle = {
        marketplaceName: 'SFP Portal v3.1',
        contentClass: 'sfp',
        Navclass: '',
        topNavClass: '',
        bottomNavClass: '',
        modalClass: null,
        sidebarImagePath: '/assets/images/sfp_logo_no_words.png',
        logo: '',
        favIcon: '',
        dropdownClass: '',
        imageClass: 'w-75 p-3 mb-2'
    };

    const finalStyle = styleReference ? { ...defaultStyle, ...styleReference } : { ...defaultStyle };

    const partnerIdentifier = vendorGroupName && subdomainDetails[vendorGroupName]
        ? vendorGroupName
        : subdomain;
    const appTitle = finalStyle.marketplaceName;
    const marketplaceName = finalStyle.marketplaceName;
    const mainClass = finalStyle.contentClass;
    let navClass = finalStyle.Navclass;
    let topNavClass = finalStyle.topNavClass;
    let bottomNavClass = finalStyle.bottomNavClass;
    let modalClass = finalStyle.modalClass;
    let sidebarImagePath = finalStyle.sidebarImagePath;
    let logo = finalStyle.logo;
    let favIcon = finalStyle.favIcon;
    let contentClass = finalStyle.contentClass;
    let dropdownClass = finalStyle.dropdownClass;
    let imageClass = finalStyle.imageClass;


    return {
        partnerIdentifier,
        appTitle,
        marketplaceName,
        mainClass,
        navClass,
        topNavClass,
        contentClass,
        dropdownClass,
        modalClass,
        sidebarImagePath,
        logo,
        favIcon,
        bottomNavClass,
        imageClass
    }
}
